const router = {
  name: "taskList",
  path: "/taskList",
  component: () => import("@/views/taskList/taskList.vue"),
  meta: {
    translate: "taskList",
    title: "taskList",
    icon: "icon-renwuliebiao"
  }
};

export default router;
