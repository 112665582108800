import request from "../requestConfig.js";
export default {
  login(data) {
    return request({
      url: "/intsmart/login",
      method: "post",
      data
    });
  },

  logout(data) {
    return request({
      url: "/intsmart/common",
      method: "post",
      data
    });
  }
};
