import request from "../../requestConfig.js";
export default {
  sysConfig(data) {
    return request({
      url: "/intsmart/cdiSystem",
      method: "post",
      data
    });
  },
  getSysConfig() {
    return request({
      url: "/intsmart/cdiSystem",
      method: "get"
    });
  }
};
