import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import ElementUI from "element-ui";
import httpList from "@/api/apiList.js";
import qs from "qs";
//样式引入
import "./style/element-variables.scss";
import "./style/element-ui.scss";
import "./style/common.scss";
import "./style/iconfont/iconfont.css";

Vue.use(ElementUI);
Vue.prototype.$qs = qs;

Vue.config.productionTip = false;
Vue.prototype.$httpList = httpList;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
