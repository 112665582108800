const router = {
  name: "taskCreate",
  path: "/taskCreate",
  component: () => import("@/views/taskCreate/taskCreate.vue"),
  meta: {
    translate: "taskCreate",
    title: "taskCreate",
    icon: "icon-renwuliebiao"
  }
};

export default router;
