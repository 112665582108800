<template>
  <div class="home">
    <div class="topHeader">
      <topHeader></topHeader>
    </div>
    <div class="context">
      <div class="menuTreeBox">
        <menuTree></menuTree>
      </div>
      <div class="container">
        <container></container>
      </div>
    </div>
  </div>
</template>

<script>
import topHeader from "@/components/topHeader.vue";
import container from "@/components/common/container.vue";
import menuTree from "@/components/menuTree.vue";
export default {
  components: { topHeader, container, menuTree }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .topHeader {
    height: 50px;
    width: 100%;
  }
  .context {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    .menuTreeBox {
      border-right: 1px solid #d9dee2;
      width: 178px;
      //保证flex不被挤压
      flex-shrink: 0;
    }
    .container {
      width: 100%;
      padding: 10px;
      padding-top: 10px;
      padding-bottom: 8px;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      height: 100%;
    }
  }
}
</style>
