const router = {
  name: "dockRecords",
  path: "/dockRecords",
  component: () => import("@/views/dockRecords/dockRecords.vue"),
  meta: {
    icon: "icon-jilu",
    title: "dockRecords"
  }
};
export default router;
