const router = {
  path: "/",
  component: () => import("@/components/common/container.vue"),
  meta: {
    translate: "systemConfig",
    title: "systemConfig",
    icon: "icon-setting"
  },
  children: [
    {
      name: "systemConfig",
      path: "/systemConfig",
      component: () => import("@/views/systemConfig/systemConfig.vue"),
      meta: {
        translate: "systemConfig",
        title: "systemConfig"
      }
    },
    {
      name: "certificateConfig",
      path: "/certificateConfig",
      component: () => import("@/views/systemConfig/certificateConfig.vue"),
      meta: {
        translate: "certificateConfig",
        title: "certificateConfig"
      }
    }
  ]
};

export default router;
