import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/common/home.vue";
import Login from "../views/common/login.vue";
import { isEmpty } from "@/utils/utils.js";

import dataStatistics from "./modules/dataStatistics/index";
import dockRecords from "./modules/dockRecords/index";
import systemConfig from "./modules/systemConfig/index";
import taskCreate from "./modules/taskCreate/index";
import taskList from "./modules/taskList/index";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    name: "login",
    component: Login
  },
  {
    path: "/ins",
    name: "home",
    component: Home,
    children: [taskCreate, taskList, dockRecords, systemConfig]
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.length === 0) {
    from.name ? next({ name: from.name }) : next({ name: "login" });
  } else {
    if (isEmpty(localStorage.getItem("ins-username"))) {
      if (to.name == "home") {
        next({ name: "login" });
      }
      if (to.name == "login") {
        next();
      }
    } else {
      next();
    }
  }
});
export default router;
export { routes };
