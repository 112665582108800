import request from "../../requestConfig.js";
export default {
  getTaskList() {
    return request({
      url: "/intsmart/task",
      method: "get"
    });
  },

  createOrUpdateTask(data,originalTaskName) {
    return request({
      url: "/intsmart/task?originalTaskName=" + originalTaskName,
      method: "post",
      data
    });
  },

  deleteTask(data) {
    return request({
      url: "/intsmart/task",
      method: "delete",
      data
    });
  },
  //获取prismart标准字段
  getPrismartFieldList() {
    return request({
      url: "/intsmart/prismart/field",
      method: "get",
    });
  },
  //测试prismart地址是否可用
  testServerUrl(data) {
    return request({
      url: "/intsmart/server-address/connect",
      method: "post",
      data
    });
  },
  //数据库连接测试
  testDatabase(data) {
    return request({
      url: "/intsmart/database/connect",
      method: "post",
      data
    });
  },
  //获取数据库字段名称
  getCustomerData(data) {
    return request({
      url: "/intsmart/customer/field",
      method: "post",
      data
    });
  },
    //获取默认对接地址
    getIntegrationUrl() {
      return request({
        url: "/intsmart/integration/url",
        method: "get"
      });
    },
  //开启定时任务
  startTask(taskName) {
    return request({
      url: "/intsmart/tasks/" + taskName + "/start",
      method: "get"
    });
  },
  //停止定时任务
  stopTask(taskName) {
    return request({
      url: "/intsmart/tasks/" + taskName + "/stop",
      method: "get"
    });
  },

};
