import request from "../../requestConfig.js";
export default {
    batchMsg(data) {
        return request({
            url: "/intsmart/batchMsg?taskName=" + data.taskName + "&batchId=" + data.batchId + "&begin=" + data.start + "&pageSize=" + data.count,
            method: "get",
        });
    },
    batchNum(data) {
        return request({
            url: "/intsmart/batchNum?taskName=" + data.taskName + "&batchId=" + data.batchId,
            method: "get",
        });
    }
};
