import request from "../../requestConfig.js";
export default {
  /**删除证书 */
  deleteCertificate(data) {
    return request({
      url: "/intsmart/certificate",
      method: "delete",
      data
    });
  },

  /**增加证书 */
  addOrUpdateCertificate(data) {
    return request({
      url: "/intsmart/certificate",
      method: "post",
      data
    });
  },
  /**获取证书 */
  getCertificates(data) {
    return request({
      url: "/intsmart/certificate",
      method: "get",
      data
    });
  }
};
