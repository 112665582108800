import axios from "axios";
import { getRealPath } from "@/utils/utils";
import responseHandle from "../utils/responseHandler";
import errorResponseHandler from "../utils/errorResponseHandler";
import qs from "qs";

const defaultUrl = getRealPath();
console.log(process.env.NODE_ENV);
const service = axios.create({
  baseURL:
    process.env.NODE_ENV == "development"
      ? process.env.VUE_APP_ROOT
      : defaultUrl + window.HS_CONFIG.gateway,
  withCredentials: true
});

service.interceptors.request.use(config => {
  const requestBody = {};
  let deleteUrl = "/v2/article/";
  if (
    config.method.toUpperCase() === "GET" ||
    (config.method.toUpperCase() === "DELETE" &&
      config.url.indexOf(deleteUrl) == -1)
  ) {
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormate: "repeat" });
    };
    requestBody.params = config.data;
    config.params = requestBody.params;
  }
  return config;
});

service.interceptors.response.use(
  response => {
    responseHandle(response.data);
    return response.data;
  },
  error => {
    errorResponseHandler(error.response);
    return Promise.reject(error);
  }
);

export default service;
