// import i18n from "../i18n";
// import store from "../store";
// import { Notification } from "element-ui";
import router from "../router";
function errorResponseHandler(res) {
  if (res.resultCode == 1502) {
    router.push({
      name: "login",
    });
  }
}
export default errorResponseHandler;
