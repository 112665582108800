function getRealPath() {
  //获取当前网址，如： http://localhost:8083/myproj/view/my.jsp
  let curWwwPath = window.document.location.href;
  //获取主机地址之后的目录，如： myproj/view/my.jsp
  let pathName = window.document.location.pathname;
  let pos = curWwwPath.indexOf(pathName);
  //获取主机地址，如： http://localhost:8083
  let localhostPaht = curWwwPath.substring(0, pos);
  // let localhostPaht = curWwwPath.substring(0, pos);
  //获取带"/"的项目名，如：/myproj
  // let projectName = pathName.substring(0, pathName.substr(1).indexOf('/') + 1);
  // 得到了 http://localhost:8083/myproj
  // let realPath = localhostPaht + '/' + projectName;
  // // let realPath = localhostPaht + window.HS_CONST.API_PROJECTNAME;
  // // let realPath=localhostPaht+'/shopweb';
  // // let realPath=localhostPaht;
  return localhostPaht;
}
//判断任何对象是否为空     字符串有长度就不算空
function isEmpty(data) {
  let type = Object.prototype.toString
    .call(data)
    .split(" ")[1]
    .replace("]", "");
  if (["Array", "String"].includes(type)) {
    return data && data.length > 0 ? false : true;
  } else if (["Undefined", "Null"].includes(type)) {
    return true;
  } else if (type == "Object") {
    return Object.keys(data).length > 0 ? false : true;
  } else {
    return false;
  }
}
export { getRealPath, isEmpty };
