<template>
  <div class="content">
    <div class="title">IntSmart</div>
    <div class="functionArea">
      <div class="userOperatesBox">
        <el-dropdown @command="userHandler">
          <div class="userBox">
            <div class="iconBox">
              <img src="../assets/person.png" alt="" />
            </div>
            <div class="usernameBox">
              <span class="userName">{{ currentUserName }}</span>
            </div>
            <div>
              <i class="el-icon-caret-bottom" style="color: #cccccc"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="exit"
                >{{ $t("button.logout") }}<i class="iconfont icon-tuichu"
              /></el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="languageChangeBox">
        <el-dropdown @command="languageHandler">
          <div class="languageItemBox">
            <i
              class="iconfont icon-ziranyuyanchuli"
              style="margin-right: 10px"
            ></i>
            <span>{{
              languageList.find(item => item.code == currentLanguage).name
            }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in languageList"
              :key="index"
              :command="item.code"
            >
              <div
                style="
                        display: flex;
                        align-items: center;
                        align-content: center;
                        justify-content: flex-start;
                        "
              >
                {{ item.name }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    languageList() {
      return window.INS_CONFIG.languageList;
    },
    currentLanguage() {
      return this.$root.$i18n.locale;
    },
    currentUserName() {
      return localStorage.getItem("ins-username");
    }
  },
  methods: {
    languageHandler(data) {
      this.$root.$i18n.locale = data;
    },
    userHandler(data) {
      if (data == "exit") {
        localStorage.clear();
        this.$router.push({ name: "login" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  // background: wheat;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid rgb(217, 222, 226);
  display: flex;
  .title {
    width: 178px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: 1px solid rgb(217, 222, 226);
  }
  .functionArea {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-content: center;
    flex: 1;
    .userBox {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      padding: 7px;
      .iconBox {
        margin-right: 7px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        img {
          height: 22px;
          width: 22px;
        }
      }
      .usernameBox {
        margin-right: 5px;
      }
    }
  }
  .languageChangeBox {
    margin: 0 10px;
    .languageItemBox {
      padding: 7px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      .languageIcon {
        margin-right: 7px;
      }
    }
  }
}
</style>
