<template>
  <div>
    <el-menu :unique-opened="true" :router="false" @select="menuSelect" :default-active="active_menu_item">
      <div v-for="(category, index) in menuData" :key="index">
        <div v-if="category.children">
          <el-submenu
            :index="category.path + index"
            :popper-append-to-body="false"
          >
            <template slot="title">
              <div class="submenuConetent">
                <div><i :class="'iconfont ' + category.meta.icon"></i></div>
                <div style="margin-left: 5px">
                  <span>{{ $t("title." + category.meta.title) }}</span>
                </div>
              </div>
            </template>
            <el-menu-item
              v-for="(view, vindex) in category.children"
              :key="vindex"
              :index="view.path"
              style="min-width: 177px; max-width: 177px; height: fit-content"
            >
              <div class="menuItemContent">
                <span> {{ $t("title." + view.meta.title) }}</span>
              </div>
            </el-menu-item>
          </el-submenu>
        </div>
        <div v-else>
          <el-menu-item :index="category.path">
            <div class="submenuConetent">
              <div><i :class="'iconfont ' + category.meta.icon"></i></div>
              <div style="margin-left: 5px">
                <span>{{ $t("title." + category.meta.title) }}</span>
              </div>
            </div>
          </el-menu-item>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { routes } from "@/router/index";
export default {
  data(){
    return{
      active_menu_item: ""
    }

  },
  computed: {
    menuData() {
      return routes[1].children;
    },
  },
  created() {
  },
  methods: {
    menuSelect(index) {
      this.$router.push({
        name: index.replace("/", ""),
      });
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        this.active_menu_item = to.path;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.submenuConetent {
  display: flex;
  justify-content: flex-start;
}
.menuItemContent {
  text-align: left;
  span {
    display: inline-block;
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
    line-height: 25px !important;
  }
}
::v-deep .el-menu-item.is-active {
  background-color: rgb(236, 245, 255) !important ;
}
.el-menu {
  border-right: none;
}
</style>
