import commonApi from "./apiList/commonApi";
import systemConfig from "./apiList/systemConfig/systemConfig";
import certificateConfig from "./apiList/systemConfig/certificateConfig";
import taskApi from "./apiList/taskList/taskApi";
import dockRecords from "./apiList/dockRecords/dockRecords";
const apiList = {
  ...commonApi,
  ...systemConfig,
  ...certificateConfig,
  ...taskApi,
  ...dockRecords
};
export default apiList;
