/* eslint-disable prettier/prettier */
<template>
  <div class="loginPageBox">
    <div class="logo">
      <img
        src="../../assets/login/login_logo_blue.png"
        width="160px"
        height="30px"
        alt=""
      />
    </div>
    <div class="loginBox">
      <div class="loginBoxTitle">
        <p class="title">{{ $t("title.loginTitle") }}</p>
      </div>
      <div class="loginForm">
        <el-form
          :model="loginForm"
          :hide-required-asterisk="true"
          :rules="loginFormRules"
          ref="loginForm"
        >
          <el-form-item prop="username" class="input">
            <el-input v-model="loginForm.username">
              <i
                slot="prefix"
                class="el-input__icon el-icon-my-user customIcon"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password" class="input">
            <el-input
              v-model="loginForm.password"
              type="password"
              show-password
              @keyup.enter.native="login()"
            >
              <i slot="prefix" class="el-input__icon el-icon-my-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login()" style="width: 100%">{{
              $t("button.login")
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      loginFormRules: {
        username: [{ required: true, message: " ", trigger: blur }],
        password: [{ required: true, message: " ", trigger: blur }],
      },
      loginForm: {
        username: "",
        password: ""
      },
      taskListNum: null,
    };
  },
  methods: {
    //aes加密算法
    encrypt(word) {
      var key = CryptoJS.enc.Utf8.parse("59c432a830ac3cddd1b0013ae8d4e4e3");
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return "{AES}" + encrypted.toString();
    },
    login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          let submitInfo = {
            username: this.loginForm.username,
            password: this.encrypt(this.loginForm.password)
          };
          return new Promise((resolve, reject) => {
            this.$httpList
              .login(submitInfo)
              .then(res => {
                if (res.resultCode == 1001) {
                  localStorage.setItem("ins-username", this.loginForm.username);
                  this.$httpList.getTaskList().then(response => {
                    if (response.data.task.length == 0) {
                      this.$router.push({
                        name: "taskCreate"
                      });
                    } else {
                      this.$router.push({
                        name: "taskList"
                      });
                    }
                  });

                  resolve(res);
                } else {
                  this.$message.error("用户名或密码错误。");
                }
              })
              .catch(err => {
                reject(err);
              });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-my-user {
  margin-left: 5px;
  background: url("../../assets/login/user.png") center no-repeat;
}

.el-icon-my-lock {
  margin-left: 5px;
  background: url("../../assets/login/lock.png") center no-repeat;
}

.loginPageBox {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url("../../assets/login/ic_bg.png");
  background-attachment: fixed;
  background-size: cover;
  align-items: center;
  justify-content: center;
  align-content: center;
  .logo {
    position: fixed;
    top: 50px;
    left: 80px;
  }
  .loginBox {
    height: 50%;
    width: 23%;
    border-radius: 10px;
    box-shadow: 4px 4px 16px 0px rgba(54, 62, 74, 0.7);
    .loginBoxTitle {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 15%;
      background: url("../../assets/login/loginrightbg.png");
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
    .loginForm {
      background: white;
      padding: 3% 15%;
      border-radius: 10px;

      .el-form-item {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
      }
      /deep/ .el-input--prefix .el-input__inner {
        padding-left: 42px !important;
      }
      /deep/ .el-input {
        border-radius: 8px !important;
        .el-input__inner {
          border-radius: 8px !important;
        }
      }
      .otherLoginBox {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
